import { apiURL, ENV_TYPE } from "../../utils/config"
import React, { useRef, useCallback } from 'react';

const ThreeJsViewer = ({user, organization, models}) => {
    const iFrameRef = useRef(null);

    const sendMessage = useCallback(() => {
        const message = {
            type: 'MOTORICA_CONFIG',
            payload: {
                config: {
                    apiURL: apiURL,
                    userName: user.email,
                    envType: ENV_TYPE,
                },
                models: models,
                attributes: organization.subscription?.attributes ?? {}
            },
        };
        iFrameRef.current.contentWindow.postMessage(message, '*');
    }, [user, models, organization]);

    const setIFrameRef = useCallback(node => {
        if (node !== null && node !== iFrameRef.current) {
            iFrameRef.current = node;
            iFrameRef.current.addEventListener('load', sendMessage);
        }
    }, [sendMessage]);

    if (user == null) {
        return <></>
    }
    return (
        <div
            style={{
                width: "100vw",
                height: "calc(100vh - 64px)",
                position: "absolute",
                top: "64px",
                left: "0px",
                overflowX: "hidden",
                overflowY: "hidden",
            }}
        >  
            <iframe
                title="application"
                src="/main.html"
                style={{
                    width: "100vw",
                    height: "100vh",
                    overflow: "hidden",
                    display: "block",
                    border: "none",
                    margin: "0px",  
                }}
                sandbox='allow-scripts allow-same-origin allow-forms allow-downloads allow-modals allow-popups'
                id="threejsviewer"
                ref={setIFrameRef}
            />
        </div>
    )
};

export default ThreeJsViewer;