export const apiURL = process.env.REACT_APP_MOTORICA_API_URL

export const basePath = process.env.REACT_APP_MOTORICA_ORY_API

export const oryJwtTokenizer = "jwt_motorica_api_gw"
export const oryJwtTokenTtl = process.env.REACT_APP_MOTORICA_ORY_JWT_TTL_MINS

export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE

export const backendAliveCheckInterval = 5000

export const BUILD_DATE = '20250219';
export const BUILD_GIT_HASH = 'f014ff8';
export const BUILD_GIT_BRANCH = 'develop';
