import { Fragment } from 'react'

import { Disclosure, DisclosureButton, Menu, Transition, MenuSeparator, MenuItems, MenuItem, MenuButton } from '@headlessui/react'
import { Bars3Icon, UserCircleIcon, XMarkIcon, PencilIcon, DocumentTextIcon, XCircleIcon, AcademicCapIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';

export default function NavBar({ user, organization, logoutUrl }) {

  const navigate = useNavigate();
  
  return (
    <Disclosure as="nav" className="bg-black">
      {({ open }) => (
        <>
          <div className="w-fullv px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="/assets/avatar_03.png"
                    alt="Motorica logo"
                  />
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-black text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon
                        className="ml-2 my-1 h-8 w-8 rounded-full text-white"

                      />
                      <span className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                        {user?.email ?? ""}
                      </span>
                    </MenuButton>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      transition
                      className="w-52 origin-top-right rounded-xl border border-white/5 bg-black p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                      anchor="bottom end"
                    >
                      <MenuItem>
                      <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/20">
                            <AcademicCapIcon className="size-4 fill-white/30" />
                            <a
                            href="https://static1.squarespace.com/static/63650e2ece9c2f59c302558c/t/668e37a6339bf67c94c082b8/1720596391292/Quickstart_guide.pdf"
                            target={"_blank"}
                              rel="noreferrer"
                            >
                              Quickstart Guide
                            </a>
                          </button>
                      </MenuItem>
                      {user?.email &&
                        <>
                        <MenuItem>
                          <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/20">
                            <PencilIcon className="size-4 fill-white/30" />
                            <a
                                href={`https://docs.google.com/forms/d/e/1FAIpQLSc8VvEiaKDL079nZZ2zQXV1Rkw3g8B7EwzfHAo112W98DTFRg/viewform?usp=pp_url&entry.587861470=${user?.email}`}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                Provide feedback
                            </a>
                          </button>
                        </MenuItem>
                        {organization?.subscription && 
                          <MenuItem>
                            <button
                              className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/20"
                              onClick={() => navigate("settings")}>
                              <AdjustmentsHorizontalIcon className="size-4 fill-white/30" />
                              Settings
                            </button>
                          </MenuItem>
                        }
                        <MenuItem>
                          <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/20">
                            <DocumentTextIcon className="size-4 fill-white/30" />
                            <a
                              href="https://www.motorica.ai/terms-of-service"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              Terms of Service
                            </a>
                          </button>
                        </MenuItem>
                        </>
                      }
                      {logoutUrl && <>
                        <MenuSeparator className="my-1 h-px bg-gray-800" />
                        <MenuItem>
                          <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/20">
                            <XCircleIcon className="size-4 fill-white/30" />
                            <a
                              href={logoutUrl}
                              target={"_blank"}
                              rel="noreferrer"
                            >
                                Sign out
                            </a>
                          </button>
                        </MenuItem>
                      </>}
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}
