import { useEffect, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle, Description } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/outline'

const showDialogIfLastLoginBefore = Date.parse("2022-01-01");

function TermsOfServiceDialog({ lastLogin }) {

  let [isOpen, setIsOpen] = useState(false);
  let [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (lastLogin !== undefined && !isInitialized) {
      if (new Date(lastLogin) < showDialogIfLastLoginBefore) {
        setIsOpen(true);
      }
      setIsInitialized(true);
    }
  }, [lastLogin, isInitialized]);
  
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
      <DialogPanel className="relative w-1/2 bg-black p-4 text-gray-400 border border-white rounded-lg">
        <button 
          onClick={() => setIsOpen(false)} 
          className="absolute top-2 right-2 text-white -top-4 -right-4"
        >
          <XCircleIcon className="size-10 text-black fill-white/100"/>
        </button>
        <DialogTitle className="text-white text-lg font-bold">We’ve Updated Our Terms of Service</DialogTitle>
        <Description>
          Our Terms of Service have been updated. Please take a moment to review the changes <a href="https://www.motorica.ai/terms-of-service" target="_blank" rel="noreferrer" className="text-white underline">here</a>. By using the platform, you acknowledge and accept these updates.
          Thank you for your continued trust and support.
        </Description>
      </DialogPanel>
    </Dialog>
  )
}

export default TermsOfServiceDialog