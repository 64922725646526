import React, { useEffect, useState } from "react"
import { useCookies } from 'react-cookie'
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { handleAuth } from "./utils/ory"
import { oryJwtTokenTtl, basePath } from "./utils/config"
import { getUserInfo } from "./utils/backend";

import NavBar from "./components/NavBar"
import LoaderScreen from "./components/LoaderScreen"

import UserRegistration from "./pages/UserRegistration"
import ThreeJsViewer from "./pages/ThreeJs"
import SettingsPage from "./pages/Settings"
import TermsOfServiceDialog from "./components/TermsOfServiceDialog"

const App = () => {
  const navigate = useNavigate();

  const [session, setSession] = useState();
  const [user, setUser] = useState();
  const [models, setModels] = useState();
  const [organization, setOrganization] = useState();
  const [logoutUrl, setLogoutUrl] = useState();
  const [, setCookie] = useCookies(['access_token']);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState("Loading...");

  useEffect(() => {

    const handleAuthWrapper = async (setSession, setCookie, setLogoutUrl) => {
      setIsLoadingText("Authenticating...");
      try {
        await handleAuth(setSession, setCookie, setLogoutUrl);
      } catch (err) {
        console.error(err);
        window.location.replace(`${basePath}/ui/login`);
      }
      setIsLoading(false);
    }

    handleAuthWrapper(setSession, setCookie, setLogoutUrl);
    const interval = setInterval(() => {
      handleAuthWrapper(setSession, setCookie, setLogoutUrl);
    }, 1000 * 60 * oryJwtTokenTtl);

    return () => clearInterval(interval);
  }, [setSession, setCookie, setLogoutUrl, setIsLoadingText]);

  useEffect(() => {
    if (!session) return;
    setIsLoadingText("Fetch user data...");
    
    (async () => {
      try {
        const userdata = await getUserInfo(session.tokenized, {include: ['models']});
        if ((userdata.user ?? null) === null) {
          navigate("register");
        } else {
          setUser(userdata.user ?? {});
          setOrganization(userdata.organization ?? {});
          setModels(userdata.models ?? []);
        }
      } catch (err) {
          console.error(err);
          window.location.replace(`${basePath}/ui/login`);
      }
    })();
    setIsLoading(false);

  }, [session, navigate, setUser, setOrganization, setModels])

  return (
    <div className="h-screen bg-black">
      <NavBar
        user={user}
        organization={organization}
        logoutUrl={logoutUrl}
      />

      {(!session || isLoading) &&
        <LoaderScreen
          text={isLoadingText}
        />
      }

      <TermsOfServiceDialog lastLogin={user?.lastLogin}/>
      
      <Routes>
        <Route path="/" element={
          <ThreeJsViewer
            user={user}
            models={models}
            organization={organization}
          />
        } />
        <Route path="/register" element={
          <UserRegistration
            session={session}
            setIsLoading={setIsLoading}
            setIsLoadingText={setIsLoadingText}
          />
        } />
        <Route path="/settings" element={
          <SettingsPage
            user={user}
            organization={organization}
            setOrganization={setOrganization}
            session={session}
          />
        } />

      </Routes>
    </div>
  )
}

export default App